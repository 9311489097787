 <template>
  <div class="brance">
     <div class="brance-continar">
     <div class="brancetitle" >
        <!-- <span>{{title}}</span> -->
		<TitleMore 
		  :title="['工作动态']"
		  :isMore="false" 
		
		  @handleTab="handleTab"
		></TitleMore>
     </div>
      <div class="lifeheathcontent">
         <div class="heathbanners">
           <Brancebanners v-show="activeIndex===0" :banners="organlist" :isremembrance="true"></Brancebanners>
		   <!-- <Brancebanners v-show="activeIndex===1" :banners="bodylist" :isremembrance="true"></Brancebanners>
		   <Brancebanners v-show="activeIndex===2" :banners="cornealist" :isremembrance="true"></Brancebanners> -->
         </div>
         <Brancenews 
		 v-show="activeIndex===0"
		 :isremembrance="true"
         :newslist="organlist" 
         :nodename="'zgxb_wcxx'"
          @childByValue='childByValue'></Brancenews>
		<!-- <Brancenews
		 v-show="activeIndex===1"
		 :isremembrance="true"
		 :newslist="bodylist" 
		 :nodename="'mhxx_ytjx'"
		  @childByValue='childByValue'></Brancenews>
		 <Brancenews
		 v-show="activeIndex===2"
		 :isremembrance="true"
		 :newslist="cornealist" 
		 :nodename="'mhxx_jmjx'"
		  @childByValue='childByValue'></Brancenews> -->
      </div>
    </div>
  </div>
</template>

<script>
import apiUrls from '@api'
import TitleMore from "@components/TitleMore.vue";
import Brancebanners from '../Learnplatform/Lifeheath/components/heathbanners'
import Brancenews from '../Learnplatform/Lifeheath/components/heathnews'
export default {
  components: {
	TitleMore,
    Brancebanners,
    Brancenews
  },
  name: 'Brance',
  data () {
    return {
      organlist: [],
	  bodylist: [],
	  cornealist: [],
      title: '工作动态',
	  activeIndex:0
    }
  },
  created() {
  	this.title = this.$route.query.title
  },
  mounted () {
    apiUrls.getbodydonate({node:'zgxb_wcxx'}).then(res=>{
    this.organlist = res.results.data
    });
   /* apiUrls.getbodydonate({node:'mhxx_ytjx'}).then(res=>{
    this.bodylist = res.results.data
    });
    apiUrls.getbodydonate({node:'mhxx_jmjx'}).then(res=>{
    this.cornealist = res.results.data
    }) */
  },
  methods : {
    childByValue (childByValue) {
      this.organlist = childByValue
    },
	handleTab(index) {
	  this.activeIndex = index;
	}
  }
}
</script> 

<style lang="less" scoped>
.brance {
  background-color: #f5f5f5;
    .brance-continar{
        width: 1200px;
        min-height: 400px;
        padding-bottom: 40px;
        margin: 0 auto;
        background-color: #f5f5f5;
        &::before {
            display: block;
            content: ".";
            clear: both;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
    .brancetitle  {
          margin-top: 10px;
          font-size: 23px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;  
      }
       .lifeheathcontent {
        min-height: 300px;
        background-color: #ffffff;  
        box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
        padding-top: 56px;
        .heathbanners {
          height: 400px;
          background-color: pink;
        }
      }
}
</style>
